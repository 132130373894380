import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

function Contact() {
  return (
    <Box
      id="contact"
      sx={{
        width: '100%',
        p: 2, // 控制 padding
      }}
    >
      {/* Contact Us 標題 */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          mb: 4, // 控制 margin-bottom
          color: 'primary.main',
        }}
      >
        Contact Us
      </Typography>

      <Grid container spacing={4} alignItems="center">
        {/* 左側：台灣聯絡資訊 */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: 'primary.main',
              }}
            >
              WEIYAN TECHNOLOGY CO., LTD. (Taiwan)
            </Typography>
            {/* Location */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <LocationOnIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Location:
                </Typography>
                <Typography variant="body1">
                  14F., No. 33, Sec. 2, Sanmin Rd., Banqiao Dist., New Taipei City 220337, Taiwan (R.O.C.)
                </Typography>
              </Box>
            </Box>

            {/* Email */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <EmailIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Email:
                </Typography>
                <Link href="mailto:service@weiyantc.com" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  service@weiyantc.com
                </Link>
              </Box>
            </Box>

            {/* Call */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Call:
                </Typography>
                <Link href="tel:+886229586701" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  +886 2 2958 6701
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* 右側：台灣地圖 */}
        <Grid item xs={12} md={6}>
          <Box
            component="iframe"
            title="map-taiwan"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12160.933797602409!2d121.47563287856403!3d25.01821253248088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a95d7248fad9%3A0xba78784587b24551!2zMjIw5paw5YyX5biC5p2_5qGl5Yy65LiJ5rCR6Lev5LqM5q61MzPomZ8xNOaokw!5e0!3m2!1szh-TW!2stw!4v1728203798478!5m2!1szh-TW!2stw"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} alignItems="center">
        {/* 左側：馬來西亞聯絡資訊 */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: 'primary.main',
              }}
            >
               WEIYAN TECHNOLOGY SDN BHD. (Malaysia)
            </Typography>
            {/* Location */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <LocationOnIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Location:
                </Typography>
                <Typography variant="body1">
                  No 20, JLN TTC 22, TAMAN TEKNOLOGI CHENG, 75260 MELAKA, MALAYSIA
                </Typography>
              </Box>
            </Box>

            {/* Email */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <EmailIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Email:
                </Typography>
                <Link href="mailto:sales@weiyanmy.com" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  sales@weiyanmy.com
                </Link>
              </Box>
            </Box>

            {/* Call */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Call:
                </Typography>
                <Link href="tel:+6063347822" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  +60 6 334 7822
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* 右側：馬來西亞地圖 */}
        <Grid item xs={12} md={6}>
          <Box
            component="iframe"
            title="map-malaysia"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.711924416009!2d102.2273566648449!3d2.261088993873989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d1fa9738a5b40f%3A0x953623b0c057727f!2zMjAsIEphbGFuIFR0YyAyMiwgVGFtYW4gVGVrbm9sb2dpIENoZW5nLCA3NTI2MCBNZWxha2EsIOmmrOS-huilv-S6ng!5e0!3m2!1szh-TW!2stw!4v1735622658591!5m2!1szh-TW!2stw"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
